import { call, put, takeEvery } from 'redux-saga/effects'
import { getConsultantRatings_API} from '../../../../Config/Api';
import { createNotification } from '../../../../Config/NotificationToast';
import { logOut } from "../../../../utils/Helper";
import { instance } from "../../../auth/axiosInstance";

export default function* consultantRatings() {
    yield takeEvery('GET_CONSULTANT_RATINGS_DATA', getConsultantRatingsDataSaga);
}

const getConsultantRatingsData = async (data) => {
    try {
        const response = await instance.get(`${getConsultantRatings_API}?start_date=${data.filter_from}&end_date=${data.filter_to}`);
        return response;
    } catch (errors) {
        console.log("errors", errors);

        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}

function* getConsultantRatingsDataSaga(action) {
    try {
        const response = yield call(getConsultantRatingsData, action.data);
        const ConsultantRatingsDataRes = response
        if (ConsultantRatingsDataRes.statusCode === 200) {
            yield put({ type: 'GET_CONSULTANT_RATINGS_DATA_SUCCESS', consultantRatingsRes: ConsultantRatingsDataRes.data });
            if(ConsultantRatingsDataRes.data.length===0)
                createNotification('error', 'No data found')
        } else {
            if (ConsultantRatingsDataRes.statusCode === 401) {
                logOut('error', ConsultantRatingsDataRes.message)
            } else {
                createNotification('error', ConsultantRatingsDataRes.message);
                yield put({ type: 'GET_CONSULTANT_RATINGS_DATA_FAILURE', message: ConsultantRatingsDataRes.message });
            }
        }
    } catch (e) {
        yield put({ type: 'GET_CONSULTANT_RATINGS_DATA_FAILURE', message: "" });
    }
}

import * as type from '../../constants';

const initialState = {
    qmsDashboardData: [],
    loading: false,
    error: null,
}

export function qmsDashboard(state = initialState, action) {
    switch (action.type) {
        case type.GET_QMS_DASHBOARD_DATA:
            return {
                ...state,
                loading: true,
            }
        case type.GET_QMS_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                qmsDashboardData: action.qmsDashboardDataRes,
                loading: false,
                error: null
            }
        case type.GET_QMS_DASHBOARD_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}
import { call, put, takeEvery } from 'redux-saga/effects'
import { getQMSDashboardData_API} from '../../../../Config/Api';
import { createNotification } from '../../../../Config/NotificationToast';
import { logOut } from "../../../../utils/Helper";
import { instance } from "../../../auth/axiosInstance";

export default function* qmsDashboard() {
    yield takeEvery('GET_QMS_DASHBOARD_DATA', getQMSDashboardDataSaga);
}

const getQMSDashboardData = async (data) => {
    try {
        const response = await instance.get(`${getQMSDashboardData_API}?filter_month=${data.month?.toString()?.padStart(2, '0')}&filter_year=${data.year}`);
        return response;
    } catch (errors) {
        console.log("errors", errors);

        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}

function* getQMSDashboardDataSaga(action) {
    try {
        const response = yield call(getQMSDashboardData, action.data);
        const qmsDashboardDataRes = response
        if (qmsDashboardDataRes.statusCode === 200) {
            yield put({ type: 'GET_QMS_DASHBOARD_DATA_SUCCESS', qmsDashboardDataRes: qmsDashboardDataRes.data });
        } else {
            if (qmsDashboardDataRes.statusCode === 401) {
                logOut('error', qmsDashboardDataRes.message)
            } else {
                createNotification('error', qmsDashboardDataRes.message);
                yield put({ type: 'GET_QMS_DASHBOARD_DATA_FAILURE', message: qmsDashboardDataRes.message });
            }
        }
    } catch (e) {
        yield put({ type: 'GET_QMS_DASHBOARD_DATA_FAILURE', message: "" });
    }
}

import * as type from '../../constants';

const initialState = {
    consultantRatings: [],
    loading: false,
    error: null,
}

export function consultantRatings(state = initialState, action) {
    switch (action.type) {
        case type.GET_CONSULTANT_RATINGS_DATA:
            return {
                ...state,
                loading: true,
            }
        case type.GET_CONSULTANT_RATINGS_DATA_SUCCESS:
            return {
                ...state,
                consultantRatings: action.consultantRatingsRes,
                loading: false,
                error: null
            }
        case type.GET_CONSULTANT_RATINGS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}
import * as type from '../../constants';

const initialState = {
    guestComments: [],
    loading: false,
    error: null,
}

export function guestComments(state = initialState, action) {
    switch (action.type) {
        case type.GET_GUEST_COMMENTS_DATA:
            return {
                ...state,
                loading: true,
            }
        case type.GET_GUEST_COMMENTS_DATA_SUCCESS:
            let data=[...action.guestCommentsDataRes]
            data.forEach((item)=>{
                item.highlights_of_experience = item?.user_output?.find(item=> item.qus_id===10)?.answer ? JSON.parse(item?.user_output?.find(item=> item.qus_id===10)?.answer) : '-'
                item.improvement = item?.user_output?.find(item=> item.qus_id===11)?.answer ? JSON.parse(item?.user_output?.find(item=> item.qus_id===11)?.answer) : '-'
                item.therapists_recognised = item?.user_output?.find(item=> item.qus_id===4)?.answer ? JSON.parse(item?.user_output?.find(item=> item.qus_id===4)?.answer) : '-'
                item.service_staff_recognised = item?.user_output?.find(item=> item.qus_id===15)?.answer ? JSON.parse(item?.user_output?.find(item=> item.qus_id===15)?.answer) : '-'
            })
            return {
                ...state,
                guestComments: data,
                loading: false,
                error: null
            }
        case type.GET_GUEST_COMMENTS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import './ProgramFeedbacks.scss';
import moment from 'moment';
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import DatePicker from 'react-datepicker';
import { visuallyHidden } from '@mui/utils';
import Divider from '@mui/material/Divider';
import {FormGroup, Label} from "reactstrap";
import Toolbar from '@mui/material/Toolbar';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import { CircularProgress } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import calendarIcon from '../../../assets/images/CommonComponent/calendarIcon.png';
import {stableSort, rowOptions} from '../../../utils/Helper'
import * as routes from "../../../Router/RoutesURL";
import { useSelector, useDispatch } from 'react-redux';
import {getProgramFeedbackListAction} from '../../../redux/actions/ReportAction/ProgramFeedbackAction/ProgramFeedbackAction'

const MainHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
}))

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    if (orderBy === "duration") orderBy = "durationOne"
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
        id: 'title',
        disablePadding: true,
        label: 'PROGRAM TITLE',
    },
    {
        id: 'guest_name',
        disablePadding: false,
        label: 'GUEST NAME',
    },
    {
        id: 'check_in_check_out',
        disablePadding: false,
        label: 'CHECK IN / CHECK OUT',
    },
    {
        id: 'submitted_on',
        disablePadding: false,
        label: 'SUBMITTED ON',
    },
    {
        id: 'action',
        disablePadding: false,
        label: 'ACTION',
        disableSorting: true

    },
];

const EnhancedTableHead = (props) => {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) =>
                (<TableCell
                    key={headCell.id}
                    align='left'
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                >
                    <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                        hideSortIcon={headCell.id === 'action' ? true : false}
                    >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>)
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { pageNos, rowsPerPageNos, setShowLoader, showLoader, activeTab } = props;
    const dispatch = useDispatch();
    const localData = JSON.parse(localStorage.getItem("filtersReport"))
    const [searchText, setSearchText] = useState(localData?.programFeedback?.filter_text ||'');
    const [fromDate, setFromDate] = useState(localData?.programFeedback?.filter_from || format(new Date(), "yyyy-MM-01"));
    const [toDate, setToDate] = useState(localData?.programFeedback?.filter_to || format(new Date(), "yyyy-MM-dd"));

    useEffect(() => {
        if (activeTab == "ProgramFeedbacks") {
            const localData = JSON.parse(localStorage.getItem("filtersReport"))
            if(!localData?.programFeedback?.filter_text){
                setSearchText('')
            }

            if(!localData?.programFeedback?.filter_from){
                setFromDate(format(new Date(), "yyyy-MM-01"))
            }

            if(!localData?.programFeedback?.filter_to){
                setToDate(format(new Date(), "yyyy-MM-dd"))
            }
        }
    }, [activeTab]);

    useEffect(() => {
        const localData = JSON.parse(localStorage.getItem("filtersReport"))
        let filtersValues = { "filter_text": searchText, "filter_from": fromDate, "filter_to": fromDate !== toDate ? toDate : '', };
        localStorage.setItem("filtersReport", JSON.stringify({...localData, programFeedback: filtersValues}))
        setShowLoader(true)

    }, [searchText, fromDate, toDate])


    const handleChangeSearch = (e) => {
        setSearchText(e.target.value)
        const sendRequest = {
            "pageNo": pageNos,
            "pageSize": rowsPerPageNos,
            "filter_text": e.target.value,
            "filter_from": fromDate,
            "filter_to": fromDate !== toDate ? toDate : '',
        };
        dispatch(getProgramFeedbackListAction(sendRequest));
    }

    const changeFromDate = (val) => {
        const formattedDate = format(val, "yyyy-MM-dd");
        setFromDate(formattedDate)
        const sendRequest = {
            "pageNo": pageNos,
            "pageSize": rowsPerPageNos,
            "filter_text": searchText,
            "filter_from": formattedDate,
            "filter_to": fromDate !== toDate ? toDate : '',
        };
        dispatch(getProgramFeedbackListAction(sendRequest));
    }

    const changeToDate = (val) => {
        const formattedDate = format(val, "yyyy-MM-dd");
        setToDate(formattedDate)
        const sendRequest = {
            "pageNo": pageNos,
            "pageSize": rowsPerPageNos,
            "filter_text": searchText,
            "filter_from": fromDate,
            "filter_to": formattedDate,
        };
        dispatch(getProgramFeedbackListAction(sendRequest));
    }

    return (
        <>
            <Toolbar sx={{
                pl: { sm: 2 },
                pt: { sm: 2 },
                pb: { sm: 2 },
                pr: { xs: 1, sm: 1 },

            }}
            >
                <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
                    <MainHeading
                        sx={{ flex: '1 1 100%', }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Program Feedbacks List
                    </MainHeading>
                </div>
                <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-9 col-xxl-9 LevelDiv'>
                    <div className='DurationDiv d-flex align-items-center'>
                        <Label>Date:</Label>
                        <div className='dateBlock'>
                            {fromDate == "" ?
                                <div className="dayDateTextDiv" ><p className="dayDateTextPlaceholder">From</p></div>
                                : <div className="dayDateTextDiv" ><p className="dayDateText">{(moment(fromDate, "YYYY-MM-DD").format('DD/MM/YYYY'))}</p></div>
                            }
                            <div className='datePickerDiv'>
                                <DatePicker
                                    selected={new Date(moment(fromDate, "YYYY-MM-DD").format('MM-DD-YYYY'))}
                                    maxDate={new Date()}
                                    onChange={date => { changeFromDate(date) }}
                                    popperPlacement={"bottom"}
                                    customInput={
                                        <img src={calendarIcon} className="calIcon" alt="" />
                                    }
                                    showYearDropdown={true}
                                    showMonthDropdown={true}
                                />
                            </div>
                        </div>
                        <div className='mx-2'>to</div>
                        <div className='dateBlock'>
                            {toDate == "" ?
                                <div className="dayDateTextDiv" ><p className="dayDateTextPlaceholder">To</p></div>
                                : <div className="dayDateTextDiv" ><p className="dayDateText">{fromDate !== toDate && (moment(toDate, "YYYY-MM-DD").format('DD/MM/YYYY'))}</p></div>
                            }
                            <div className='datePickerDiv'>
                                <DatePicker
                                    selected={new Date(moment(toDate, "YYYY-MM-DD").format('MM-DD-YYYY'))}
                                    minDate={new Date((moment(fromDate, "YYYY-MM-DD").add(1, 'days')).format('MM-DD-YYYY'))}
                                    maxDate={new Date()}
                                    onChange={date => { changeToDate(date) }}
                                    popperPlacement={"bottom"}
                                    customInput={
                                        <img src={calendarIcon} className="calIcon" alt="" />
                                    }
                                    showYearDropdown={true}
                                    showMonthDropdown={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="separator"></div>

                    <div className="mr-sm-2 searchDiv class-search ">
                        <input
                            className="form-control mr-sm-2 class-search"
                            type="search"
                            placeholder=" Search Keyword"
                            aria-label="Search"
                            value={searchText}
                            onChange={handleChangeSearch}
                        ></input>
                    </div>
                </div>
            </Toolbar>
            <Divider />
        </>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const ProgramFeedbacksTable = (props) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [getProgramFeedbackListMainData, setgetProgramFeedbackListMainData] = useState('');
    const [showNext, setShowNext] = useState(false);
    const [showPrev, setShowPrev] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate()
    const getProgramFeedbackList = useSelector(state => state?.getProgramFeedbackList);
    const getProgramFeedbackListDataRes = useSelector(state => state?.getProgramFeedbackList?.getProgramFeedbackList);
    const btnPrev = ["btnPrev", showPrev ? "" : "opacityBtns"];
    const btnNext = ["btnNext", showNext ? "" : "opacityBtns"];
    const { activeTab } = props;
    const dispatch = useDispatch();

    useEffect(()=>{
        if(!getProgramFeedbackList.loading)
            setShowLoader(false)
        if(!getProgramFeedbackList.loading && !getProgramFeedbackListDataRes?.data?.data && !getProgramFeedbackList.error){
            setShowLoader(true)
        }
    }, [getProgramFeedbackList.loading])

    useEffect(() => {
        if (activeTab == "ProgramFeedbacks") {
            const localData = JSON.parse(localStorage.getItem("filtersReport"))
        
            const sendRequest = {
                "pageNo": 1,
                "pageSize": rowsPerPage,
                "filter_text": localData?.programFeedback?.filter_text || '' ,
                "filter_from": (localData?.programFeedback?.filter_from) || format(new Date(), "yyyy-MM-01"),
                "filter_to": localData?.programFeedback?.filter_to || (format(new Date(), "yyyy-MM-dd") === format(new Date(), "yyyy-MM-01") ? '' : format(new Date(), "yyyy-MM-dd"))
            };
            dispatch(getProgramFeedbackListAction(sendRequest));
        }
    }, [activeTab]);
    
    useEffect(() => {
        if (getProgramFeedbackList.error !== "Ananda program feedback data does not exists") {
            setgetProgramFeedbackListMainData(getProgramFeedbackListDataRes && getProgramFeedbackListDataRes?.data?.data);
            if (getProgramFeedbackListDataRes?.data?.current_page_no === 1) {
                setShowPrev(false)
            } else {
                setShowPrev(true)
            }

            if (getProgramFeedbackListDataRes?.data?.current_page_no === getProgramFeedbackListDataRes?.data?.total_pages) {
                setShowNext(false)
            } else {
                setShowNext(true)
            }
        } else {
            setgetProgramFeedbackListMainData('');
        }
    }, [getProgramFeedbackListDataRes, getProgramFeedbackList])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = getProgramFeedbackListDataRes && getProgramFeedbackListMainData.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const handleChangePageNext = (newPage) => {
        setPage(newPage);
        setShowLoader(true)
        const filterdata = JSON.parse(localStorage.getItem("filtersReport"));
        const sendRequest = {
            "pageNo": getProgramFeedbackListDataRes?.data?.current_page_no + 1,
            "pageSize": rowsPerPage,
            "filter_text": filterdata?.programFeedback?.filter_text,
            "filter_from": filterdata?.programFeedback?.filter_from || format(new Date(), "yyyy-MM-01"),
            "filter_to": filterdata?.programFeedback?.filter_to || format(new Date(), "yyyy-MM-dd"),
        };
        dispatch(getProgramFeedbackListAction(sendRequest));
    };
    
    const handleChangePagePrev = (newPage) => {
        setPage(newPage);
        setShowLoader(true)
        const filterdata = JSON.parse(localStorage.getItem("filtersReport"));
        const sendRequest = {
            "pageNo": getProgramFeedbackListDataRes?.data?.current_page_no - 1,
            "pageSize": rowsPerPage,
            "filter_text": filterdata?.programFeedback?.filter_text,
            "filter_from": filterdata?.programFeedback?.filter_from || format(new Date(), "yyyy-MM-01"),
            "filter_to": filterdata?.programFeedback?.filter_to || format(new Date(), "yyyy-MM-dd"),
        };
        dispatch(getProgramFeedbackListAction(sendRequest));
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.value);
        setPage(page);

        const filterdata = JSON.parse(localStorage.getItem("filtersReport"));

        const sendRequest = {
            "pageNo": 1,
            "pageSize": event.value,
            "filter_text": filterdata?.programFeedback?.filter_text,
            "filter_from": filterdata?.programFeedback?.filter_from || format(new Date(), "yyyy-MM-01"),
            "filter_to": filterdata?.programFeedback?.filter_to || format(new Date(), "yyyy-MM-dd"),
        };
        dispatch(getProgramFeedbackListAction(sendRequest));
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getProgramFeedbackListDataRes && getProgramFeedbackListMainData?.length) : 0;

    const handleViewProgramFeedback = (data) => {
        navigate(routes.VIEW_PROGRAM_FEEDBACK, { state: { data } })
    };

    return (
        <div className="ProBox">
            <Box sx={{ width: '100%' }} className="ProgramFeedbackBox">
                <Paper sx={{ width: '100%', mb: 2, borderRadius: 2 }}>
                    <EnhancedTableToolbar numSelected={selected.length} pageNos={page}
                        showLoader={showLoader}
                        setShowLoader={setShowLoader}
                        rowsPerPageNos={rowsPerPage} 
                        activeTab ={activeTab} />
                {
                    showLoader ?
                    <div className='w-100 text-center py-5'>
                        <CircularProgress sx={{'color': '#74613C'}}/>
                    </div>
                    :
                    <>
                        
                        {getProgramFeedbackListMainData && <TableContainer sx={{ pt: 1, pr: 3, pb: 3, pl: 3 }}>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"

                                size='small'
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={getProgramFeedbackListDataRes && getProgramFeedbackListMainData?.length}
                                />
                                <TableBody>
                                    {
                                        stableSort(getProgramFeedbackListMainData, getComparator(order, orderBy))
                                            .slice((rowsPerPage * (page - 1)), (rowsPerPage * (page) + rowsPerPage))
                                            .map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <React.Fragment key={index}>
                                                        <TableRow hover onClick={(event) => handleClick(event, row.title)} role="checkbox"

                                                            tabIndex={-1}
                                                            
                                                            key={index}

                                                            >
                                                            <TableCell component="th" id={labelId} scope="row" padding="none" width={'30%'}>{row.title}</TableCell>
                                                            <TableCell align="left">{row.guest_name}</TableCell>
                                                            <TableCell align="left"> 
                                                            {row.check_in ? moment(row.check_in).format('DD/MM/YYYY'): ''} - {row.check_out ? moment(row.check_out).format('DD/MM/YYYY'): ''}
                                                            </TableCell>
                                                            <TableCell align="left">{row.submitted_on ? moment(row.submitted_on).format('DD/MM/YYYY'): ''}</TableCell>

                                                            <TableCell align="left"
                                                                className=' ViewEditClick '
                                                                onClick={() => { handleViewProgramFeedback(row) }}><i className="fas fa-eye mr-2"></i>View</TableCell>                                                        
                                                        </TableRow>
                                                    </React.Fragment>
                                                );
                                            })}
                                    {emptyRows > 0 && (
                                        <TableRow>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </>
                }
                </Paper>
                {
                    getProgramFeedbackListMainData
                    &&
                    <div className='paginationBlock'>
                        <div className='textData'><p>Items per page: </p></div>
                        <FormGroup className="FormGroup has-float-label rowsSelect class-menu-dropdown ">
                            <Select
                                placeholder={rowsPerPage}
                                className="react-select"
                                classNamePrefix="react-select"
                                value={rowsPerPage}
                                options={rowOptions}
                                isSearchable={false}
                                onChange={handleChangeRowsPerPage}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#F5F5F5',
                                        primary: '#74613C',
                                    },
                                })}
                                menuPlacement="top"
                                menuPosition="fixed"
                                styles={{
                                    menu: (base) => ({
                                        ...base,
                                        top: 'auto',
                                        bottom: 0,
                                    }),
                                }}
                            />
                        </FormGroup>
                        {getProgramFeedbackListDataRes?.data && <p className='endText'>   {getProgramFeedbackListDataRes?.data?.start} - {getProgramFeedbackListDataRes?.data?.end} of {getProgramFeedbackListDataRes?.data?.total}</p>}
                        <p>
                            <button className={btnPrev.join(' ')} onClick={() => { handleChangePagePrev(page) }}>
                                <ArrowBackIosNewSharpIcon className='iconBtn' />
                            </button>
                            <button className={btnNext.join(' ')} onClick={() => { handleChangePageNext(page) }}>
                                <ArrowForwardIosSharpIcon className='iconBtn' />
                            </button></p>
                    </div>   
                }
            </Box>
        </div>
    );
}
export default ProgramFeedbacksTable;
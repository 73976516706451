import React from 'react'
import { Modal } from 'react-bootstrap'
import './CustomModal.scss'

function CustomModal({show, setShow, onClickNo, onClickYes, title="Are you sure?", content="" }) {
  return (
    <div>
        <Modal centered className="custom-modal br-8" show={show} onHide={() => { setShow(false) }}>
                <Modal.Header className='w-100'>
                    <div className="modalText-guest text-center w-100 pb-4">{title}</div>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <label htmlFor="exampleFormControlInput1" className="form-label DeleteDesc-guest">
                        {content}
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-success greenButton mr-3" onClick={onClickYes}>
                        Yes</button>
                    <button type="button" className="btn btn-danger redButton " onClick={onClickNo}>
                        No</button>
                </Modal.Footer>
            </Modal>
    </div>
  )
}

export default CustomModal
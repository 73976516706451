import { call, put, takeEvery } from 'redux-saga/effects'
import { getGuestComments_API} from '../../../../Config/Api';
import { createNotification } from '../../../../Config/NotificationToast';
import { logOut } from "../../../../utils/Helper";
import { instance } from "../../../auth/axiosInstance";

export default function* guestComments() {
    yield takeEvery('GET_GUEST_COMMENTS_DATA', getGuestCommentsDataSaga);
}

const getGuestCommentsData = async (data) => {
    try {
        const response = await instance.get(`${getGuestComments_API}?start_date=${data.filter_from}&end_date=${data.filter_to}`);
        return response;
    } catch (errors) {
        console.log("errors", errors);

        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}

function* getGuestCommentsDataSaga(action) {
    try {
        const response = yield call(getGuestCommentsData, action.data);
        const guestCommentsDataRes = response
        if (guestCommentsDataRes.statusCode === 200) {
            yield put({ type: 'GET_GUEST_COMMENTS_DATA_SUCCESS', guestCommentsDataRes: guestCommentsDataRes.data });
            if(guestCommentsDataRes.data.length===0)
                createNotification('error', 'No data found')
        } else {
            if (guestCommentsDataRes.statusCode === 401) {
                logOut('error', guestCommentsDataRes.message)
            } else {
                createNotification('error', guestCommentsDataRes.message);
                yield put({ type: 'GET_GUEST_COMMENTS_DATA_FAILURE', message: guestCommentsDataRes.message });
            }
        }
    } catch (e) {
        console.log(e)
        yield put({ type: 'GET_GUEST_COMMENTS_DATA_FAILURE', message: "" });
    }
}

import React, { Fragment, useEffect, useState } from 'react'
import './QMSScoreboard.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getQMSDashboardData } from '../../../redux/actions/ReportAction/QMSScoreboardAction/QMSScoreboardAction';
import { Box, CircularProgress, Divider, Paper, Toolbar, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { monthsOptions, yearsOptions } from '../../../Config/Constant';
import Select from "react-select";

const columns = [
    {
      label: "Total",
      key: "total"
    },
    {
      label: "D",
      key: "D"
    },
    {
      label: "%",
      key: "percent_D"
    },
    {
      label: "N",
      key: "N"
    },
    {
      label: "%",
      key: "percent_N"
    },
    {
      label: "P",
      key: "P"
    },
    {
      label: "%",
      key: "percent_P"
    },
    {
      label: "Score",
      key: "percent_score"
    }
  ];

const getPreviousMonth = ({month, year})=>{
    const currentDate = new Date(year, month - 1);
    currentDate.setMonth(currentDate.getMonth() - 1);
    const previousMonth = currentDate.getMonth() + 1; 
    const previousYear = currentDate.getFullYear();

    const formattedPreviousMonth = previousMonth < 10 ? `0${previousMonth}` : previousMonth;
    return `${formattedPreviousMonth}/${previousYear}`
}


function getCurrentYearToDate({month, year}) {
    let to=''
    if((new Date().getMonth() + 1)===month && new Date().getFullYear()===year)
        to=`${new Date().getDate()?.toString()?.padStart(2, '0')}/${month?.toString()?.padStart(2, '0')}/${year}`
    else
        to=`${(new Date(year, month, 0).getDate())?.toString()?.padStart(2, '0')}/${month?.toString()?.padStart(2, '0')}/${year}`

    let from=''
    if(month<4)
        from=`01/04/${year-1}`
    else
        from=`01/04/${year}`

    return `${from} - ${to}`
}

function getLastYearToDate({month, year}) {
    let to=`${(new Date(year, month, 0).getDate())?.toString()?.padStart(2, '0')}/${month?.toString()?.padStart(2, '0')}/${year-1}`

    let from=''
    if(month<4)
        from=`01/04/${year-2}`
    else
        from=`01/04/${year-1}`

    return `${from} - ${to}`
}



const SubHeadingRow = ()=>{
    return (
        <tr className='sub-heading'>
            <td className='p-1 bg-lightest-brown pl-3 text-left position-sticky left-0 '>Description</td>
            {
                columns?.map((item, index)=>{
                    return (
                        <td key={index} className='p-1 bg-lightest-brown'>{item.label}</td>
                    )
                })
            }
            {
                columns?.map((item, index)=>{
                    return (
                        <td key={index} className='p-1 bg-lightest-brown'>{item.label}</td>
                    )
                })
            }
            {
                columns?.map((item, index)=>{
                    return (
                        <td key={index} className='p-1 bg-lightest-brown'>{item.label}</td>
                    )
                })
            }
            {
                columns?.map((item, index)=>{
                    return (
                        <td key={index} className='p-1 bg-lightest-brown'>{item.label}</td>
                    )
                })
            }
            {
                columns?.map((item, index)=>{
                    return (
                        <td key={index} className='p-1 bg-lightest-brown'>{item.label}</td>
                    )
                })
            }
        </tr>
    )
}

  
const MainHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
}))

function QMSScoreboard(props) {
    const [filter, setFilter] = useState({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    })

    const { activeTab } = props;
    const dispatch = useDispatch()
    const data = useSelector(state => state?.qmsDashboard);

    useEffect(()=>{
        if (activeTab == "QMS Scorecard")
            dispatch(getQMSDashboardData({...filter}))
    }, [activeTab, filter])


    return (
        <div className="QMSScoreboardBox">
            <Box sx={{ width: '100%', minHeight: '300px' }} className="guestDinacharyaBox">
                <Paper sx={{ width: '100%', minHeight: '500px', mb: 2,Radius: 2 }}>
                    <Toolbar sx={{
                        pl: { sm: 2 },
                        pt: { sm: 2 },
                        pb: { sm: 2 },
                        pr: { xs: 1, sm: 1 },
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    >
                        <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-3'>
                            <MainHeading
                                sx={{ flex: '1 1 100%', }}
                                variant="h6"
                                id="tableTitle"
                                component="div"
                            >
                                QMS Dashboard
                            </MainHeading>
                        </div>
                        <div className='d-flex'>
                            <Select
                                className="name-select"
                                classNamePrefix="select"
                                isSearchable={true}
                                name="color"
                                options={monthsOptions}
                                placeholder='Month'
                                onChange={(e)=> setFilter(prev=> ({...prev, month: e.value}))}
                                value={monthsOptions?.find(item=>item.value ===filter.month)}
                            />
                            &nbsp;&nbsp;
                            <Select
                                className="name-select"
                                classNamePrefix="select"
                                isSearchable={true}
                                name="color"
                                options={yearsOptions}
                                placeholder='Year'
                                onChange={(e)=> setFilter(prev=> ({...prev, year: e.value}))}
                                value={yearsOptions?.find(item=>item.value ===filter.year)}
                            />
                        </div>
                    </Toolbar>
                    <Divider />
                    {
                        data?.loading ?
                        <div className='w-100 text-center py-5'>
                            <CircularProgress sx={{'color': '#74613C'}}/>
                        </div>
                        :
                        <div className='dashboard'>
                            <table className='text-center' cellSpacing="0" cellPadding="0">
                                <thead className=' '>
                                    <tr className='heading '>
                                        <th className='p-3 bg-light-brown position-sticky left-0' style={{minWidth: '300px'}} ></th>
                                        <th colSpan={8} className='p-3 bg-light-brown'>Current Month</th>
                                        <th colSpan={8} className='p-3 bg-light-brown'>Current Month Last Year</th>
                                        <th colSpan={8} className='p-3 bg-light-brown'>Previous Month</th>
                                        <th colSpan={8} className='p-3 bg-light-brown'>Current Year to Date</th>
                                        <th colSpan={8} className='p-3 bg-light-brown'>Last Year to Date</th>
                                    </tr>
                                    <tr className='font-weight-bold'>
                                        <td className='p-1 bg-lighter-brown  position-sticky left-0'></td>
                                        <td colSpan={8} className='p-1 bg-lighter-brown'>{filter.month?.toString()?.padStart(2,0)}/{filter.year}</td>
                                        <td colSpan={8} className='p-1 bg-lighter-brown'>{filter.month?.toString()?.padStart(2,0)}/{filter.year-1}</td>
                                        <td colSpan={8} className='p-1 bg-lighter-brown'>{getPreviousMonth(filter)}</td>
                                        <td colSpan={8} className='p-1 bg-lighter-brown'>{getCurrentYearToDate(filter)}</td>
                                        <td colSpan={8} className='p-1 bg-lighter-brown'>{getLastYearToDate(filter)}</td>

                                    </tr>
                                </thead>
                                <tbody >
                                    <SubHeadingRow/>
                                    <tr className='font-weight-bold'>
                                        <td  className='p-1 pl-3 text-left position-sticky left-0 bg-lightest-brown' style={{color: '#74613C'}}>NET PROMOTER SCORE</td>
                                        {
                                            columns?.map((item, index)=>{
                                                return (
                                                    <td key={index} className='p-1'>{data?.qmsDashboardData?.current_month?.[0]?.user_output?.[0]?.rating_obj[item.key]?.toString() || '0'}</td>
                                                )
                                            })
                                        }
                                        {
                                            columns?.map((item, index)=>{
                                                return (
                                                    <td key={index} className='p-1 bg-lighter-grey'>{data?.qmsDashboardData?.current_month_last_year?.[0]?.user_output?.[0]?.rating_obj[item.key]?.toString() || '0'}</td>
                                                )
                                            })
                                        }
                                        {
                                            columns?.map((item, index)=>{
                                                return (
                                                    <td key={index} className='p-1'>{data?.qmsDashboardData?.last_month?.[0]?.user_output?.[0]?.rating_obj[item.key]?.toString() || '0'}</td>
                                                )
                                            })
                                        }
                                        {
                                            columns?.map((item, index)=>{
                                                return (
                                                    <td key={index} className='p-1 bg-lighter-grey'>{data?.qmsDashboardData?.current_year_to_date?.[0]?.user_output?.[0]?.rating_obj[item.key]?.toString() || '0'}</td>
                                                )
                                            })
                                        }
                                        {
                                            columns?.map((item, index)=>{
                                                return (
                                                    <td key={index} className='p-1'>{data?.qmsDashboardData?.last_year_to_date?.[0]?.user_output?.[0]?.rating_obj[item.key]?.toString() || '0'}</td>
                                                )
                                            })
                                        }
                                    </tr>
                                    <tr className=' heading'>
                                        <td colSpan={2} className='p-3 text-left heading position-sticky left-0 border-right-0 bg-brown text-white' style={{minWidth: '300px',}}>QMS Score Card</td>
                                        <td colSpan={40} className='bg-brown border-left-0'></td>
                                    </tr>
                                    <tr className=' heading position-sticky'>
                                        <td className='p-3 bg-light-brown position-sticky left-0'></td>
                                        <td colSpan={8} className='p-3 bg-light-brown'>Current Month</td>
                                        <td colSpan={8} className='p-3 bg-light-brown'>Current Month Last Year</td>
                                        <td colSpan={8} className='p-3 bg-light-brown'>Previous Month</td>
                                        <td colSpan={8} className='p-3 bg-light-brown'>Current Year to Date</td>
                                        <td colSpan={8} className='p-3 bg-light-brown'>Last Year to Date</td>
                                    </tr>
                                    <tr className='font-weight-bold '>
                                        <td className='p-1 bg-lighter-brown position-sticky left-0 '></td>
                                        <td colSpan={8} className='p-1 bg-lighter-brown'>{filter.month?.toString()?.padStart(2,0)}/{filter.year}</td>
                                        <td colSpan={8} className='p-1 bg-lighter-brown'>{filter.month?.toString()?.padStart(2,0)}/{filter.year-1}</td>
                                        <td colSpan={8} className='p-1 bg-lighter-brown'>{getPreviousMonth(filter)}</td>
                                        <td colSpan={8} className='p-1 bg-lighter-brown'>{getCurrentYearToDate(filter)}</td>
                                        <td colSpan={8} className='p-1 bg-lighter-brown'>{getLastYearToDate(filter)}</td>
                                    </tr>{console.log(data)}
                                    {
                                        data?.qmsDashboardData?.temp_data?.slice(1)?.map((item, index)=>{
                                            return (
                                                <Fragment key={item.qus_id}>
                                                    {
                                                        item.question_title &&
                                                        <tr className='sub-heading'> 
                                                            <td colSpan={2} className='p-3 text-left  position-sticky border-right-0 left-0' ><div className="d-flex align-items-center ">⦿ {item.question_title}</div></td>
                                                            <td colSpan={39} className=' border-left-0'></td>
                                                        </tr>
                                                    }
                                                    <SubHeadingRow/>
                                                        {
                                                            item?.user_output?.map((subItem, subIndex)=>{
                                                                let isLast = item?.user_output?.length === subIndex+1
                                                                return (
                                                                    <tr key={subIndex}>
                                                                        <td className={`p-1 pl-3 text-left bg-lightest-brown  position-sticky left-0 ${isLast ? 'font-weight-bold': ''} ${isLast && index!==0 ? 'text-center': ''}` } style={{color: isLast && index===0 ?'#74613C' : 'black'}}>{subItem.option_tag_name}</td>

                                                                        {
                                                                            columns?.map((subItem2, subIndex2)=>{
                                                                                return (
                                                                                    <td key={subIndex2} className={`p-1 ${isLast ? 'font-weight-bold': ''}`}>{data?.qmsDashboardData?.current_month?.find(e=>e.question_title === item.question_title)?.user_output?.find(e=>e.option_tag_name === subItem.option_tag_name)?.rating_obj[subItem2.key]?.toString() || '0'}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            columns?.map((subItem2, subIndex2)=>{
                                                                                return (
                                                                                    <td key={subIndex2} className={`p-1 bg-lighter-grey ${isLast ? 'font-weight-bold': ''}`}>{data?.qmsDashboardData?.current_month_last_year?.find(e=>e.question_title === item.question_title)?.user_output?.find(e=>e.option_tag_name === subItem.option_tag_name)?.rating_obj[subItem2.key]?.toString() || '0'}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            columns?.map((subItem2, subIndex2)=>{
                                                                                return (
                                                                                    <td key={subIndex2} className={`p-1 ${isLast ? 'font-weight-bold': ''}`}>{data?.qmsDashboardData?.last_month?.find(e=>e.question_title === item.question_title)?.user_output?.find(e=>e.option_tag_name === subItem.option_tag_name)?.rating_obj[subItem2.key]?.toString() || '0'}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            columns?.map((subItem2, subIndex2)=>{
                                                                                return (
                                                                                    <td key={subIndex2} className={`p-1 bg-lighter-grey ${isLast ? 'font-weight-bold': ''}`}>{data?.qmsDashboardData?.current_year_to_date?.find(e=>e.question_title === item.question_title)?.user_output?.find(e=>e.option_tag_name === subItem.option_tag_name)?.rating_obj[subItem2.key]?.toString() || '0'}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            columns?.map((subItem2, subIndex2)=>{
                                                                                return (
                                                                                    <td key={subIndex2} className={`p-1  ${isLast ? 'font-weight-bold': ''}`}>{data?.qmsDashboardData?.last_year_to_date?.find(e=>e.question_title === item.question_title)?.user_output?.find(e=>e.option_tag_name === subItem.option_tag_name)?.rating_obj[subItem2.key]?.toString() || '0'}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                </Fragment>
                                            )
                                        })
                                    } 
                                    
                                </tbody>
                            </table>
                        </div>
                    }
                </Paper>
            </Box>
        </div>
    )
}

export default QMSScoreboard